import {
  ElasticSearch,
  ElasticSearchFacetsFields,
  ElasticSearchMySizes,
  ElasticSearchProductCharacteristics,
  InnerFeedContext,
} from '@interfaces/models/elasticSearch';
import { ElasticSearchResponse } from '@interfaces/api/responses/elasticSearch';
import { getUpdatedCountriesFromFacets, mapCountryToTheSizeType } from '@helpers/utils/catalog/catalog-url-helpers';
import SearchService, { ProductSearchHeaders } from '@services/search-service';
import React, { MutableRefObject, useEffect, useState } from 'react';
import { usePreferences } from '@context/preferences.context';
import logger from '@helpers/utils/logger/client';
import useActiveDealQuery from '@hooks/deal/use-active-deal-query';
import { AxiosError, AxiosHeaders, AxiosRequestHeaders } from 'axios';
import { datadogRum } from '@datadog/browser-rum-slim';
import useUser from '@hooks/user/use-user';
import { SearchQueryIdState } from '@hooks/catalog-context/use-search-query-id';
import { getUserToken } from '@helpers/utils/catalog/catalog-tracking-utils';
import useRecentSearchParams from '@hooks/catalog-context/use-recent-search-params';
import { MySizesState } from '@interfaces/models/catalogContextProps';
import useInnerFeedContextParam from '@hooks/catalog-context/use-inner-feed-context-param';
import { useRouter } from 'next/router';
import { chainMethods } from '@helpers/utils/general';

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

interface UseFetchProducts {
  filters: ElasticSearch['filters'];
  currentPage: number;
  searchQuery: string;
  facets: ElasticSearchResponse['facets']['fields'];
  resultsPerPage: number;
  sortBy: ElasticSearch['sortBy'];
  mySizesState: MySizesState;
  setMySizesState: SetState<MySizesState>;
  setCurrentPage: SetState<number>;
  setShouldShowLoadingAnimation: SetState<boolean>;
  isManualSwitchRef: MutableRefObject<boolean>;
  requiresFetchWithoutSizes: MutableRefObject<boolean>;
  shouldFetchResults: MutableRefObject<boolean>;
  searchSessionId: string;
  assignSearchQueryId: SearchQueryIdState['assignSearchQueryId'];
  isPlpHotfiltersEnabled: boolean;
}

export const getMySizesPayload = (mySizesState: MySizesState): ElasticSearchMySizes =>
  !!mySizesState.enabled && !!mySizesState.applicable && !!mySizesState.preferences
    ? {
        isEnabled: true,
        ['universe.id']: mySizesState.preferences.universeIds,
        sizes: mySizesState.preferences.sizes,
      }
    : null;

const updateCountryFilter = (filters: ElasticSearch['filters'], facets: ElasticSearchResponse['facets']['fields']) => {
  const newFilters = { ...filters };

  // update country in 'filter' in query, e.g. country in facets can be changed after mysizes is on
  if (Array.isArray(filters?.country) && facets) {
    const countries = getUpdatedCountriesFromFacets(filters.country, facets);
    if (countries.length !== filters.country.length) {
      newFilters.country = countries;
    }
    if (!countries.length) {
      const { country, ...rest } = newFilters;
      return rest;
    }
  }

  return newFilters;
};

const handleDealHuntingLandingPage = (filters: ElasticSearch['filters'], isDealHuntingLandingPage: boolean) => {
  if (!isDealHuntingLandingPage || filters.dealEligible || filters.discount) {
    return filters;
  }
  return {
    ...filters,
    percentageDiscount: {
      gte: 1,
    },
  };
};

const useFetchProducts = ({
  filters,
  currentPage,
  searchQuery,
  facets,
  resultsPerPage,
  sortBy,
  mySizesState,
  setMySizesState,
  setCurrentPage,
  setShouldShowLoadingAnimation,
  isManualSwitchRef,
  requiresFetchWithoutSizes,
  shouldFetchResults,
  searchSessionId,
  assignSearchQueryId,
  isPlpHotfiltersEnabled,
}: UseFetchProducts) => {
  const { country, currency, language, regionName } = usePreferences();
  const { activeDeal } = useActiveDealQuery();
  const { user } = useUser();
  const { query } = useRouter();
  const hasAlertPropsInURL = !!query?.alertId || !!query?.alert_status;

  const { isRecentSearch, isSmartRecency, recentSearchDate } = useRecentSearchParams();

  const innerFeedContext = useInnerFeedContextParam();
  const isDealHuntingLandingPage = innerFeedContext === InnerFeedContext.DealHunting;

  // Result states
  const [queryResult, setQueryResult] = useState<ElasticSearchResponse>(null);

  const fetchResults = async (
    page: number = currentPage,
    payload?: Partial<ElasticSearch>,
  ): Promise<ElasticSearchResponse> => {
    const newFilters = chainMethods<ElasticSearch['filters']>(
      (refinedFilters) => updateCountryFilter(refinedFilters, facets),
      (refinedFilters) => handleDealHuntingLandingPage(refinedFilters, isDealHuntingLandingPage),
    )(filters);

    const additionalFields: ElasticSearchProductCharacteristics[] = [];
    const additionalFacetFields: ElasticSearchFacetsFields[] = [];
    // we only use dutyFree field in US
    if (country === 'US') {
      additionalFields.push('dutyFree');
      additionalFacetFields.push('dutyFree');
    }

    if (activeDeal?.active) {
      additionalFacetFields.push('dealEligible');
    }

    if (isPlpHotfiltersEnabled || hasAlertPropsInURL) {
      additionalFacetFields.push('categoryLvl1', 'categoryLvl2');
    }

    try {
      const productDetails: Partial<ElasticSearch> = {
        q: searchQuery,
        pagination: {
          offset: (page - 1) * resultsPerPage,
          limit: resultsPerPage,
        },
        sortBy,
        filters: newFilters,
        locale: {
          country,
          currency,
          language,
          sizeType: mapCountryToTheSizeType(regionName),
        },
        ...(additionalFacetFields.length > 0
          ? {
              facets: {
                fields: additionalFacetFields,
              },
            }
          : {}),
        mySizes: getMySizesPayload(mySizesState),
        ...(additionalFields.length > 0 ? { fields: additionalFields } : {}),
        options: {
          innerFeedContext,
          ...(isRecentSearch && isSmartRecency ? { lastSearchDate: Number(recentSearchDate) } : {}),
          ...(isPlpHotfiltersEnabled ? { disableHierarchicalParentFiltering: true } : {}),
        },
        ...payload,
      };

      const newSearchQueryId = assignSearchQueryId(productDetails);

      const headers: AxiosRequestHeaders = new AxiosHeaders({
        [ProductSearchHeaders.userToken]: getUserToken(user?.id),
        [ProductSearchHeaders.searchQueryId]: newSearchQueryId,
        [ProductSearchHeaders.searchSessionId]: searchSessionId,
      });

      const res = await SearchService.productSearch(productDetails, {
        headers,
        includeFacets: true,
      });
      // If mySizes is applicable, but the products returned are not applicable, we should set mySizesState to applicable: false
      if (mySizesState.applicable && res.mySizes?.isApplicable === false) {
        setMySizesState((prev) => ({ ...prev, applicable: false }));
      }
      return res;
    } catch (error) {
      // In case the endpoint is down, we should stop the loading animation. This scenario will be improved with specific error journeys
      if (error?.response?.status === 500) {
        setShouldShowLoadingAnimation(false);
      }

      if ((error as AxiosError)?.code === 'ECONNABORTED') {
        datadogRum.addError(error);
        logger.error(error, 'ECONNABORTED on PLP');
        return;
      }

      logger.error(error, 'Error fetching products');
    }
  };

  // // This hook is only triggered on /search and campaign pages
  useEffect(() => {
    if (!requiresFetchWithoutSizes.current || !filters || !resultsPerPage) {
      return;
    }
    const fetchResultsWithMySize = () => fetchResults(currentPage);
    const fetchResultsWithoutMySize = () => fetchResults(currentPage, { mySizes: null });

    const performTwoStepFetch = async () => {
      const productsWithSizes = await fetchResultsWithMySize();
      if (productsWithSizes?.items?.length > 0) {
        setQueryResult(productsWithSizes);
        return;
      }
      // If mySizes is applicable, but we don't get any products back, we should try to fetch products without mySizes
      if (mySizesState.applicable) {
        const productsWithoutSizes = await fetchResultsWithoutMySize();
        setQueryResult(productsWithoutSizes);
        return;
      }
      setQueryResult(productsWithSizes);
    };
    performTwoStepFetch().finally(() => {
      requiresFetchWithoutSizes.current = false;
    });
  }, [filters]);

  useEffect(() => {
    if (requiresFetchWithoutSizes.current || !filters || !resultsPerPage) {
      return;
    }
    (async () => {
      if (!isManualSwitchRef.current) {
        const response = await fetchResults(currentPage);
        setQueryResult(response);
      } else {
        setCurrentPage(1);
        const response = await fetchResults(1);
        setQueryResult(response);
        isManualSwitchRef.current = false;
      }
    })();
  }, [sortBy, filters, resultsPerPage, mySizesState]);

  // This is used for fetching data on page change, otherwise we have double fetches
  useEffect(() => {
    if (shouldFetchResults.current) {
      (async () => {
        const response = await fetchResults(currentPage);
        setQueryResult(response);
      })();
    }
  }, [currentPage]);

  return { queryResult };
};

export default useFetchProducts;
